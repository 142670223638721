.wrapper-success-email {
  height: 100vh;
  width: 100vw;
  background: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
}

.button-back-success{
  border: 1px solid #1cc974;
  height: 40px;
  width: 200px;
  background: transparent;
  color: #1cc974;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 50px;

}

.button-back-success:hover{
  transition:0.5s;
  background: #1cc974;
  color: #ffffff;
}

@media (max-width: 992px) { 
  .button-back-success{
    position: absolute;
    bottom: 50px;
  }
  .wrapper-success-email p{
    font-size: 30px;
    margin-bottom: 0;
  }
  .wrapper-success-email {
    
    justify-content: start;
    flex-direction: column;
    text-align: center;
  }
  .wrapper-animatin-success{
    margin-top: 40%;
  }

}

.wrapper-success-email p{
  font-size: 30px;
  color: #4d4545;
  margin-bottom: 0;
}

@media (max-width: 768px) { 
  .wrapper-success-email p{
    font-size: 20px;
    margin-bottom: 0;
  }
}

.wrapper-animatin-success{
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
}