.container-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center
}

.title-card{
font-family: Ubuntu;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
margin-top: 10px;

display: flex;
align-items: center;

color: #FFFFFF;
}

.descripition-card{
font-family: Ubuntu;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;
margin-top: 10px;

color: #FFFFFF;

}

.img-card{
  width: 100px;
  height: 100px;
  border-radius: 70px;
}



section.module-medium.parallax-medium{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px !important;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  /* background-attachment: fixed;  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

section.module-medium.parallax-medium:after{
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 8;
  background: -moz-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%); 
  background: -o-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
  background: -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
}

section.module-medium.parallax-1{
  background-image: url('../../assets/abup.jpeg');
  
  }

  .worksContainer{
    background-image: url('../../assets/loop-grafite.png');

  }