body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1,h2,h3,h4,h5,h6{
    font-weight: 900;
    color: black;
  }
  
  .wrapper{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  main{
    display: block;
    background-color: #fff;
  }
  
  .containerCuston{
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  section.module p{
    margin-bottom: 40px;
  }
  /* esseto o ultimo filho */
  section.module:last-child{ 
    margin-bottom: 0px;
  }
  
  section.module h2{ 
    margin-bottom: 40px;
    font-size: 30px;
  }
  
  section.module.content{
    min-height: 400px;
    padding: 40px 0;
    background: #333333;
  }
  
  
  section.module.parallax{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh !important;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed; /*responsavel pelo efeito parallax*/
    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  
  section.module.parallax:after{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 8;
    background: -moz-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%); 
    background: -o-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
    background: -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 70%, #000 100%);
  }
  
  section.module.parallax-1{
  background-image: url('../../../src/assets/3.png');
  
  }
  section.module.parallax-2{
    background-image: url('../../../src/assets/2.jpeg');
    
    }
  
    section.module.parallax-3{
      background-image: url('../../../src/assets/2.jpeg');
      
      }
  
  section.module.parallax h1{
    color: #fff;
    text-align: center;
    font-size: 78px;
    z-index: 50;
    text-transform: uppercase;
  }
  
  
  
  
  .box {
    padding: 20px 40px;
    background-color: rgba(61, 163, 177, 0.6);
    border: 4px solid rgba(61, 163, 177, 0.9);
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
  }
  
  .banner {
    height: 100vh;
    background-repeat: no-repeat;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  
  .banner.banner-2 {
    background-image: url("../../../src/assets/bg-man.jpeg");
  }
  
  .bg-image-grey{
    /* background-image: url('https://alphacarpetes.com.br/wp-content/uploads/2021/06/carpete-loop4.png'); */
    background-image: url('../../../src/assets/loop-grafite.png');

  }
  
  .bg-image-red{
    background-image: url("../../../src/assets/carpete-bg.jpg");
    
  }
