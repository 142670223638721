.container-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center
}

.title-card{
font-family: Ubuntu;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 29px;
margin-top: 10px;

display: flex;
align-items: center;

color: #FFFFFF;
}

.descripition-card{
font-family: Ubuntu;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
text-align: center;
margin-top: 10px;

color: #FFFFFF;

}

.img-card{
  width: 100px;
  height: 100px;
  border-radius: 70px;
}

.teste3{
  align-items: flex-start;
  display: flex;
  justify-content: start;
}
