.containerHeader{
  position: absolute;
  top: 0px;
  background: transparent;
  margin-top: 28px;
}
.containerButtonHeader{
  display: flex;
  flex-direction: row;
  
}

.wrapperButton{
  padding: 3px 14px;
  height: 28px;
}

.containerButtonHeader a{
text-decoration: none;
font-family: Ubuntu;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;

display: flex;
align-items: center;

color: #FFFFFF;


}

.containerButtonHeader .wrapperButton:hover {
  background: rgba(255, 255, 255, 0.753);
  transition:0.7s;
  /* background-image: url('../../assets/carpete-bg.jpg'); */
  border-radius: 5px;

}

.containerButtonHeader .wrapperButton:hover a{
  transition:0.7s;
  color: #4D4545;
/* color: #ffffff; */


}
