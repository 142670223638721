.cls-1, .cls-7 {
    fill: #231f20;
  }

  .cls-1, .cls-3, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: none;
    stroke: #231f20;
    stroke-miterlimit: 22.93;
    stroke-width: 0.57px;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #94191c;
  }

  .cls-5 {
    fill: #3e0000;
  }

  .cls-6 {
    fill: #d41f26;
  }

  #PRETO .cls-1 {
      animation: logo-tracado 2s ease-in-out forwards 0s, logo-preenchimento 1s ease-in-out forwards 2s ;

  }

  #PRETO .cls-3 {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    fill: transparent;
    animation: logo-preenchimento-branco 1s ease-in-out forwards 3s ;

}
  
@keyframes logo-preenchimento {
      to {
         fill: #231f20;
         stroke-width: 0px;
      }
  }

@keyframes logo-preenchimento-vermelho {
    to {
       fill: #d41f26;
       stroke-width: 0px;
    }
}

@keyframes logo-preenchimento-vermelho-escuro {
    to {
       fill: #94191c;
       stroke-width: 0px;
    }
}

@keyframes logo-preenchimento-vermelho-escuro-2 {
    to {
       fill: #3e0000;
       stroke-width: 0px;
    }
}

@keyframes logo-preenchimento-branco {
    to {
       fill: #fff;
       stroke-width: 0px;
    }
}

@keyframes logo-tracado {
    to {
        stroke-dashoffset: 0;
    }
}

#TEXTO{
    animation: logo-tracado 2s ease-in-out forwards 2s;
}

#TEXTO path{
    stroke-width: 1px;
    stroke: #231f20;
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    fill: transparent;
    animation: logo-preenchimento 0.5s ease-in-out forwards 3.5s;
}

#PRETO path {
    stroke-width: 1px;
    stroke: #231f20;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    fill: transparent;
    
  }

#VERMELHO .cls-6 {
    stroke-width: 1px;
    stroke: #d41f26;
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    fill: transparent;
    animation: logo-tracado 3s ease-in-out forwards 1s, logo-preenchimento-vermelho 0.5s ease-in-out forwards 3s;
}

#VERMELHO .cls-4 {
    stroke-width: 1px;
    stroke: #94191c;
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    fill: transparent;
    animation: logo-tracado 3s ease-in-out forwards 2s, logo-preenchimento-vermelho-escuro 0.5s ease-in-out forwards 3s;
}

#VERMELHO .cls-5 {
    stroke-width: 1px;
    stroke: #3e0000;
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    fill: transparent;
    animation: logo-tracado 3s ease-in-out forwards 2s, logo-preenchimento-vermelho-escuro-2 0.5s ease-in-out forwards 4s;
}

