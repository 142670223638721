  #menu {
    z-index: 2;
  }
  
  #menu-bar {
    width: 45px;
    height: 40px;
    margin: 30px 0 20px 20px;
    cursor: pointer;
  }
  
  .bar {
    height: 5px;
    width: 100%;
    background-color: #ffffff;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  #bar1 {
    transform: translateY(-4px);
  }
  
  #bar3 {
    transform: translateY(4px);
  }
  
  .nav {
    transition: 0.3s ease;
    display: none;
  }

  
  .nav ul {
    padding: 0 22px;
  }
  
  .nav li {
    list-style: none;
    padding: 12px 0;
  }
  
  .nav li a {
    color: white;
    font-size: 15px;
    text-decoration: none;
  }
  
  .nav li a:hover {
    font-weight: bold;
    
  }
  
  .menu-bg, #menu {
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    /* background: radial-gradient(circle, #DC052D, #DC052D);
    background: -moz-radial-gradient(circle, #DC052D, #DC052D);
    background: -webkit-radial-gradient(circle, #dc052c65, #DC052D); */
    background: -o-radial-gradient(circle, #555151, #2221212a);

    /* transition: 0.4s ease; */
    border-radius: 50%;
  }
  
  .change {
    display: block;
  }
  
  .change .bar {
    background: #ffffff !important;
  }
  
  .change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
  }
  
  .change #bar2 {
    opacity: 0;
  }
  
  .change #bar3 {
    transform: translateY(-6px) rotateZ(45deg);
  }
  
  .change-bg {
    transform: translate(-50%,-30%);
    width: 400px;
    height: 500px;
    /* transform: translate(50%,30%); */
    background-image: url('../../../assets/imagem-grafite.jpg');
    /* background-image: url('../../../assets/carpete-bg.jpg'); */
  }

  .menu-change-bg{
    transition: 0.2s ease; 
    background-image: url('../../../assets/imagem-grafite.jpg');
    border-radius: 0 60px 60px 0;  
    animation: menu-animation-translate 0.5s ease-in-out, menu-animation-border 0.4s ease-in;
  }

 @keyframes menu-animation-translate {
     0%{
transform: translateX(-100px);
     }
    100%{
transform: translateX(0px);
         
    }
 }

 @keyframes menu-animation-border {
    0%{
        border-radius: 0 0px 0px 0 ;  

    }
   100%{
    border-radius: 0 60px 60px 0 ;  
   }
}
