@media (min-width: 768px) { 
  .containerFormContact{
    display: flex; 
    flex-direction: column;
    padding: 0 50px;
    padding-bottom: 30px;
  }
  
  .containerTextContact{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 30px 0;
  }

  .containerFormContact button {
    width: 250px;
    height: 34px;
  
    background: #D3343C;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;

    align-self: center;
    margin-top: 41px;
  }
}

@media (max-width: 768px) { 
  .containerFormContact{
    display: flex; 
    flex-direction: column;
    padding: 30px 5% 30px 5%;
  }

  .containerTextContact{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
  }

  .containerFormContact button {
    width: 168px;
    height: 34px;
  
    background: #D3343C;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;

    align-self: center;
    margin-top: 16px;
  }  
}

.containerFormContact input {
  height: 32px;
  border: none;
  margin-top: 16px;
  padding-left: 15px;
  border-radius: 3px;

}

textarea:focus, input:focus{
  outline: none;
}

.containerFormContact textarea{
  margin-top: 16px;
  padding-left: 15px;
  border: none;
  border-radius: 3px;
}

.containerFormContact span {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
}

.btn-submit {
	color: #FFF;
	transition: all 0.5s;
	position: relative;
}
.btn-submit::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.1);
	transition: all 0.3s;
}
.btn-submit:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
.btn-submit::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid rgba(255,255,255,0.5);
	transform: scale(1.2,1.2);
}
.btn-submit:hover::after {
	opacity: 1;
	transform: scale(1,1);
}

