.wrapperFooter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 22px;
  grid-row-gap: 22px;
}

.conatanerContact {
  width: 130px;
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #ffffff;

}


@media (min-width: 768px) { 
  .containerWrapper{
    display: flex; 
    flex-direction: row; 
    align-items: center
  }
}

@media (max-width: 768px) { 
  .containerWrapper{
    display: flex; 
    flex-direction: column;
    justify-content: center;
  }
  .wrapperCardContato{
    display: flex;
    justify-content: center;
    position: relative;
    left: -25px;
    bottom: -25px;
  }

  .conatanerContact {
    width: 130px;
    height: 130px;
    background: #ffffff;
  }
 
}

  .inner-element {
    transform: translateZ(40px) scale(0.8);
    width: 70%;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100px;
    flex-direction: column;
    
  }

  .inner-element span {
   
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;

color: #000000;

margin-top: 30px;

  }


.parallax-effect {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.wrapper-form-contact{
  width: 100%;
  background: #D3343C;
  min-height: 432px;
  border-radius: 5px;
}

